import React, { Component } from 'react';
import { Table } from 'react-bootstrap';

export class Users extends Component {
    constructor(props) {
        super(props);
        this.state = { users: [] };
    }

    refreshList() {
        fetch(`${process.env.REACT_APP_API}/users/`)
        .then(response => response.json())
        .then(data => {
            this.setState({ users: data.data });
        });
    }

    componentDidMount() {
        this.refreshList();
    }

    render() {
        const { users } = this.state;
        return (
            <div className="container">
                <h3>Felhasznalok</h3>
                <Table className="mt-4" striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (
                            <tr key={user.id}>
                                <td>{user.username}</td>
                                <td>{user.first_name}</td>
                                <td>{user.last_name}</td>
                                <td>{user.email}</td>
                                <td>{user.phone_number}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }
}
