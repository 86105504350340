import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home } from './Home';
import { Users } from './Users';
import { Navigation } from './Navigation';
import Login from './forms/login';
import Register from './forms/register';
import ServiceProfile from './components/ServiceProfile/ServiceProfile';
import Services from './Services';
import Booking from './Booking'; // Import the Booking component
import Bookings from './Bookings'; // Import the combined bookings component
import BookingDetails from './BookingDetails'; // Import the new component

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(() => {
        return localStorage.getItem('isLoggedIn') === 'true';
    });
    const [username, setUsername] = useState(() => {
        return localStorage.getItem('username') || '';
    });

    useEffect(() => {
        localStorage.setItem('isLoggedIn', isLoggedIn.toString());
    }, [isLoggedIn]);

    useEffect(() => {
        localStorage.setItem('username', username);
    }, [username]);

    return (
        <BrowserRouter>
            <div className="App">
                <Navigation 
                    isLoggedIn={isLoggedIn} 
                    setIsLoggedIn={setIsLoggedIn} 
                    username={username} 
                    setUsername={setUsername} 
                />
                <div className="container mt-5 p-4 shadow rounded">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/services" element={<Services />} />
                        <Route path="/users" element={<Users />} />
                        <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} setUsername={setUsername} />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/serviceprofile/*" element={<ServiceProfile />} />
                        <Route path="/booking/:userId/:serviceId" element={<Booking />} />
                        <Route path="/bookings" element={<Bookings />} /> {/* Combined bookings page */}
                        <Route path="/booking-details/:userId/:serviceId/:bookingDate" element={<BookingDetails />} />
                    </Routes>
                </div>
            </div>
        </BrowserRouter>
    );
}

export default App;
