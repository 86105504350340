import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import huLocale from '@fullcalendar/core/locales/hu'; // Import Hungarian locale
import './BookingDetails.css'; // Custom CSS for this component

const BookingDetails = () => {
    const { userId, serviceId, bookingDate } = useParams(); // Extract userId, serviceId, and bookingDate from URL
    const [profileData, setProfileData] = useState(null);
    const [appointments, setAppointments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentUserId, setCurrentUserId] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        let decodedUserId = null;

        if (token) {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            const payload = JSON.parse(jsonPayload);
            decodedUserId = payload.user_id;
        }

        setCurrentUserId(decodedUserId);
    }, []);

    useEffect(() => {
        if (userId) {
            fetch(`${process.env.REACT_APP_API}/api/user-profile/${userId}/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
                .then(response => response.json())
                .then(data => setProfileData(data))
                .catch(error => {
                    console.error('Error fetching profile data:', error);
                    setError('Error fetching profile data');
                })
                .finally(() => setLoading(false));

                fetch(`${process.env.REACT_APP_API}/api/appointments-by-service-id/${serviceId}/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
                .then(response => response.json())
                .then(data => {
                    const formattedAppointments = data.data.map(event => ({
                        id: event.id,
                        title: event.service_name || 'Unnamed Service',
                        start: new Date(event.start_time),
                        end: new Date(event.end_time),
                        bookedBy: event.booked_by,
                    }));
                    setAppointments(formattedAppointments);
                })
                .catch(error => console.error('Error fetching appointments:', error));
        }
    }, [userId, serviceId]);

    const handleEventClick = (clickInfo) => {
        const event = clickInfo.event;
        const appointmentId = event.id;

        if (!event.extendedProps.bookedBy) {
            fetch(`${process.env.REACT_APP_API}/api/appointments/${appointmentId}/book/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        fetchAppointments();
                    } else {
                        alert(data.error || 'Failed to book the appointment.');
                    }
                })
                .catch(error => console.error('Error booking appointment:', error));
        } else if (event.extendedProps.bookedBy === currentUserId) {
            fetch(`${process.env.REACT_APP_API}/api/appointments/${appointmentId}/unbook/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
                body: JSON.stringify({ booked_by: currentUserId })
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        fetchAppointments();
                    } else {
                        alert(data.error || 'Failed to unbook the appointment.');
                    }
                })
                .catch(error => console.error('Error unbooking appointment:', error));
        } else {
            alert('This appointment is already booked.');
        }
    };

    const fetchAppointments = () => {
        if (serviceId) {
            fetch(`${process.env.REACT_APP_API}/api/appointments-by-service-id/${serviceId}/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
                .then(response => response.json())
                .then(data => {
                    const formattedAppointments = data.data.map(event => ({
                        id: event.id,
                        title: event.service_name || 'Unnamed Service',
                        start: new Date(event.start_time),
                        end: new Date(event.end_time),
                        bookedBy: event.booked_by,
                    }));
                    setAppointments(formattedAppointments);
                })
                .catch(error => console.error('Error fetching appointments:', error));
        }
    };

    const calendarOptions = {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: 'timeGridWeek',
        initialDate: bookingDate, // Set the calendar's initial date to the booking date
        headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'timeGridWeek,timeGridDay'
        },
        events: appointments,
        selectable: false,
        selectMirror: false,
        dayMaxEvents: true,
        slotDuration: '00:10:00',
        slotLabelInterval: '00:10',
        slotMinTime: "08:00:00",
        slotMaxTime: "24:00:00",
        allDaySlot: false,
        locale: huLocale,
        buttonText: {
            today: 'Today',
            month: 'Month',
            week: 'Week',
            day: 'Day'
        },
        firstDay: 1,
        height: 'auto',
        eventClick: handleEventClick,
        eventClassNames: function(arg) {
            if (arg.event.extendedProps.bookedBy) {
                return ['booked-event'];
            }
            return [];
        }
    };

    if (loading) {
        return <div>Loading data...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="booking-details-container">
            {profileData && profileData.user_data && (
                <div className="profile-container">
                    <div className="profile-picture">
                        <img
                            id="profile-picture-img"
                            src={profileData.profile_picture_url ? `${process.env.REACT_APP_API}${profileData.profile_picture_url}` : `${process.env.REACT_APP_API}/media/profile_pictures/default`}
                            alt="Profile"
                        />
                    </div>
                    <div className="profile-details">
                        <p><strong>First Name:</strong> {profileData.user_data.first_name}</p>
                        <p><strong>Last Name:</strong> {profileData.user_data.last_name}</p>
                        <p><strong>Email:</strong> {profileData.user_data.email}</p>
                        <p><strong>Phone Number:</strong> {profileData.user_data.phone_number}</p>
                    </div>
                </div>
            )}

            <hr />

            <div className="calendar-container">
                <FullCalendar {...calendarOptions} />
            </div>
        </div>
    );
};

export default BookingDetails;
