// src/Bookings.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Bookings.css'; // Import a single CSS file for styling both sections

const Bookings = () => {
    const [myBookings, setMyBookings] = useState([]);
    const [appointmentsAtMyServices, setAppointmentsAtMyServices] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchMyBookings();
        fetchAppointmentsAtMyServices();
    }, []);

    const fetchMyBookings = () => {
        fetch(`${process.env.REACT_APP_API}/api/my-bookings/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            },
        })
            .then(response => response.json())
            .then(data => setMyBookings(Array.isArray(data) ? data : []))
            .catch(error => {
                console.error('Error fetching my bookings:', error);
                setMyBookings([]);
            });
    };

    const fetchAppointmentsAtMyServices = () => {
        fetch(`${process.env.REACT_APP_API}/api/bookings-at-my-services/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                const filteredAppointments = data.filter(appointment => appointment.booked_by_data !== null);
                setAppointmentsAtMyServices(filteredAppointments);
            })
            .catch(error => console.error('Error fetching appointments at my services:', error));
    };

    const handleBookingClick = (userId, serviceId, bookingDate) => {
        navigate(`/booking-details/${userId}/${serviceId}/${bookingDate}`);
    };

    return (
        <div className="bookings-container">
            <h2>Foglalások másoknál</h2>
            <table className="bookings-table">
                <thead>
                    <tr>
                        <th>Szolgáltatás</th>
                        <th>Kezdési idő</th>
                        <th>Befejezési idő</th>
                        <th>Szolgáltató neve</th>
                        <th>Szolgáltató email címe</th>
                        <th>Szolgáltató telefonszáma</th>
                        <th>Akció</th>
                    </tr>
                </thead>
                <tbody>
                    {myBookings.map((booking, index) => {
                        const bookingDate = new Date(booking.start_time).toISOString().split('T')[0];
                        const userId = booking.user_data ? booking.user_data.id : null;
                        const serviceId = booking.service;

                        return (
                            <tr key={index}>
                                <td>{booking.service_name || 'N/A'}</td>
                                <td>{new Date(booking.start_time).toLocaleString()}</td>
                                <td>{new Date(booking.end_time).toLocaleString()}</td>
                                <td>{booking.user_data ? `${booking.user_data.first_name} ${booking.user_data.last_name}` : 'N/A'}</td>
                                <td>{booking.user_data ? booking.user_data.email : 'N/A'}</td>
                                <td>{booking.user_data ? booking.user_data.phone_number : 'N/A'}</td>
                                <td>
                                    <button onClick={() => handleBookingClick(userId, serviceId, bookingDate)}>
                                        Megnyitás a naptárban
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <h2>Foglalások nálunk</h2>
            <table className="bookings-table">
                <thead>
                    <tr>
                        <th>Szolgáltatás</th>
                        <th>Kezdési idő</th>
                        <th>Befejezési idő</th>
                        <th>Foglaló neve</th>
                        <th>Foglaló email címe</th>
                        <th>Foglaló telefonszáma</th>
                    </tr>
                </thead>
                <tbody>
                    {appointmentsAtMyServices.map((appointment, index) => (
                        <tr key={index}>
                            <td>{appointment.service_name || 'N/A'}</td>
                            <td>{new Date(appointment.start_time).toLocaleString()}</td>
                            <td>{new Date(appointment.end_time).toLocaleString()}</td>
                            <td>{appointment.booked_by_data.first_name || 'N/A'} {appointment.booked_by_data.last_name || 'N/A'}</td>
                            <td>{appointment.booked_by_data.email || 'N/A'}</td>
                            <td>{appointment.booked_by_data.phone_number || 'N/A'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Bookings;
