// src/forms/login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './login.css';
import { setTokens } from '../utils';
import { jwtDecode } from 'jwt-decode'; // Helyes importálás

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

function Login({ setIsLoggedIn, setUsername }) {
    const [username, setUsernameLocal] = useState('');
    const [password, setPassword] = useState('');
    const csrftoken = getCookie('csrftoken');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const response = await fetch(`${process.env.REACT_APP_API}/api/login/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify({ username, password }),
        });
        const data = await response.json();
        if (response.status === 200) {
            setIsLoggedIn(true);
            setUsername(username);
            setTokens(data.access, data.refresh); // Tokenek tárolása

            // JWT token dekódolása és a user ID elmentése
            const decodedToken = jwtDecode(data.access);
            localStorage.setItem('user_id', decodedToken.user_id);

            navigate('/');
        } else {
            alert(data.error || 'Bejelentkezési hiba történt.');
        }
    };

    return (
        <div className="containerLogin">
            <h2>Bejelentkezés</h2>
            <form onSubmit={handleSubmit}>
                <label>
                    Felhasználónév:
                    <input type="text" value={username} onChange={e => setUsernameLocal(e.target.value)} />
                </label>
                <label>
                    Jelszó:
                    <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
                </label>
                <button type="submit">Bejelentkezés</button>
            </form>
        </div>
    );
}

export default Login;
