// src/Navigation.js
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import './Navbar.css'; // Import the CSS for styling

export function Navigation({ isLoggedIn, setIsLoggedIn, username, setUsername }) {
    const navigate = useNavigate();
    const [profilePictureUrl, setProfilePictureUrl] = useState(null);

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        if (storedUsername) {
            setUsername(storedUsername);
        }

        if (isLoggedIn) {
            fetch(`${process.env.REACT_APP_API}/api/user-profile/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
                .then(response => response.json())
                .then(data => {
                    setProfilePictureUrl(data.profile_picture_url);
                })
                .catch(error => console.error('Error fetching profile data:', error));
        }
    }, [isLoggedIn, setUsername]);

    const handleLogout = async () => {
        try {
            const refreshToken = localStorage.getItem('refresh_token');
            await fetch(`${process.env.REACT_APP_API}/api/logout/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ refresh: refreshToken }),
            });
        } catch (error) {
            console.error('Logout error:', error);
        } finally {
            localStorage.clear();
            setIsLoggedIn(false);
            setUsername('');
            navigate('/');
        }
    };

    
    return (
        <Navbar bg="dark" expand="lg" variant="dark">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <NavLink className="d-inline p-2 mx-3 btn btn-dark" to="/">
                        Főoldal
                    </NavLink>
                    {isLoggedIn && (
                        <>
                            <NavLink className="d-inline p-2 mx-3 btn btn-dark" to="/services">
                                Szolgáltatások
                            </NavLink>
                            <NavLink className="d-inline p-2 mx-3 btn btn-dark" to="/bookings">
                                Foglalások
                            </NavLink>
                            <NavLink className="d-inline p-2 mx-3 btn btn-dark" to="/users">
                                Felhasználók
                            </NavLink>
                        </>
                    )}
                </Nav>
                <Nav>
                    {!isLoggedIn ? (
                        <>
                            <NavLink className="d-inline p-2 mx-3 btn btn-dark" to="/login">
                                Bejelentkezés
                            </NavLink>
                            <NavLink className="d-inline p-2 mx-3 btn btn-dark" to="/register">
                                Regisztráció
                            </NavLink>
                        </>
                    ) : (
                        <NavDropdown
                            title={
                                <span className="d-flex align-items-center">
                                    {profilePictureUrl && (
                                        <img
                                            src={`${process.env.REACT_APP_API}${profilePictureUrl}`}
                                            alt="Profile"
                                            className="profile-pic-small"
                                        />
                                    )}
                                    {username}
                                </span>
                            }
                            id="basic-nav-dropdown"
                            className="d-inline p-2 mx-3 btn btn-dark"
                        >
                            <NavDropdown.Item as={NavLink} to="/serviceprofile">
                                Profil
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={handleLogout}>
                                Kijelentkezés
                            </NavDropdown.Item>
                        </NavDropdown>
                    )}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}
