import React, { Component } from 'react';

export class Home extends Component {
    render() {
        return (
            <div className="container">
                <h2>Idofoglalo WebApp</h2>
                <p>
                    Az elképzelésem egy olyan alkalmazás létrehozására irányul, amely egy órafoglalási platformot biztosít szalonoknak, edzőknek és más szolgáltatóknak, valamint lehetőséget nyújt a felhasználóknak (klienseknek) ezek szolgáltatásainak böngészésére és foglalására. Az alkalmazás célja az, hogy egyszerűsítse az időpont foglalást és a szolgáltatások választását, valamint lehetőséget biztosítson a szolgáltatóknak az ügyfélbázisuk bővítésére és a marketing aktivitásokra.
                </p>

                <hr /> {/* Separator line */}

                <h2>Tervezett Funkciók</h2>

                <h3>Szolgáltatók</h3>
                <ul>
                    <li><strong>Időpontfoglalások Kezelése</strong>
                        <ul>
                            <li>Időpontok létrehozása: Szolgáltatók létrehozhatnak új időpontokat, ahol megadják az elérhető időpontokat és a hozzájuk tartozó szolgáltatásokat.</li>
                            <li>Időpontok módosítása: Szolgáltatók módosíthatják a már meglévő időpontokat, például változtathatják az időpontot, a szolgáltatást vagy a státuszt.</li>
                            <li>Időpontok törlése: Szolgáltatók törölhetik a már meglévő időpontokat, ha azokat már nem kívánják elérhetővé tenni.</li>
                            <li>Időpontfoglalások elfogadása: Szolgáltatók elfogadhatják a kliensek foglalási kérelmeit.</li>
                            <li>Alternatív időpontok ajánlása: Szolgáltatók alternatív időpontokat ajánlhatnak, amelyeket a kliensnek kell elfogadnia.</li>
                        </ul>
                    </li>
                    <li><strong>Szolgáltatások Kezelése</strong>
                        <ul>
                            <li>Szolgáltatások létrehozása: Szolgáltatók létrehozhatnak új szolgáltatásokat, ahol megadják a szolgáltatás nevét, leírását, árát és időtartamát.</li>
                            <li>Szolgáltatások módosítása: Szolgáltatók módosíthatják a már meglévő szolgáltatásokat, például változtathatják az árat, leírást vagy időtartamot.</li>
                            <li>Szolgáltatások törlése: Szolgáltatók törölhetik a már meglévő szolgáltatásokat, ha azokat már nem kívánják elérhetővé tenni.</li>
                        </ul>
                    </li>
                    <li><strong>Profil Kezelése</strong>
                        <ul>
                            <li>Profilkép feltöltése: Szolgáltatók feltölthetik és módosíthatják a profilképüket.</li>
                            <li>Profil adatok módosítása: Szolgáltatók módosíthatják a személyes adataikat, például a nevüket, elérhetőségeiket és egyéb információkat.</li>
                            <li>Egyéni Téma Kiválasztása: A felhasználók számára elérhető egyéni megjelenési beállítások.</li>
                        </ul>
                    </li>
                </ul>

                <hr /> {/* Separator line */}

                <h3>Kliensek</h3>
                <ul>
                    <li><strong>Időpontfoglalások Kezelése</strong>
                        <ul>
                            <li>Időpontok foglalása: Kliensek böngészhetik az elérhető szolgáltatások és időpontok listáját, és lefoglalhatják a számukra megfelelő időpontokat.</li>
                            <li>Időpontok módosítása: Kliensek módosíthatják a már lefoglalt időpontjaikat, például változtathatják az időpontot vagy a szolgáltatást.</li>
                            <li>Időpontok törlése: Kliensek törölhetik a már lefoglalt időpontjaikat, ha azokra már nincs szükségük.</li>
                        </ul>
                    </li>
                    <li><strong>Szolgáltatások Böngészése</strong>
                        <ul>
                            <li>Szolgáltatások keresése és szűrése: Kliensek kereshetnek és szűrhetnek a szolgáltatások között különböző kritériumok alapján, például ár, időtartam vagy szolgáltató szerint.</li>
                            <li>Szolgáltatások értékelése és véleményezése: Kliensek értékelhetik és véleményezhetik az igénybe vett szolgáltatásokat, ezzel segítve más klienseket a döntésben.</li>
                        </ul>
                    </li>
                    <li><strong>Profil Kezelése</strong>
                        <ul>
                            <li>Profilkép feltöltése: Kliensek feltölthetik és módosíthatják a profilképüket.</li>
                            <li>Profil adatok módosítása: Kliensek módosíthatják a személyes adataikat, például a nevüket, elérhetőségeiket és egyéb információkat.</li>
                            <li>Egyéni Téma Kiválasztása: A felhasználók számára elérhető egyéni megjelenési beállítások.</li>
                        </ul>
                    </li>
                </ul>

                <hr /> {/* Separator line */}

                <h3>Ingyenes és Fizetős Funkciók</h3>
                <h4>Ingyenes Funkciók</h4>
                <ul>
                    <li>Időpontok létrehozása, módosítása, törlése: Alapvető időpontkezelési funkciók a szolgáltatóknak.</li>
                    <li>Szolgáltatások létrehozása, módosítása, törlése: Alapvető szolgáltatáskezelési funkciók a szolgáltatóknak.</li>
                    <li>Időpontok foglalása, módosítása, törlése: Alapvető időpontfoglalási funkciók a klienseknek.</li>
                    <li>Profilkép feltöltése és módosítása: Alapvető profilkezelési funkciók mindkét felhasználói csoport számára.</li>
                    <li>Szolgáltatások keresése és szűrése: Alapvető keresési és szűrési funkciók a klienseknek.</li>
                    <li>Naptár Integráció: Vizuális naptár nézet a foglalások kezelésére.</li>
                </ul>

                <h4>Fizetős Funkciók</h4>
                <ul>
                    <li>Foglalási Kérelmek Elfogadása és Alternatív Időpont Ajánlása: Speciális időpontkezelési funkciók a szolgáltatóknak.</li>
                    <li>Értesítések és Emlékeztetők: Automatikus értesítések és emlékeztetők a foglalások előtt.</li>
                    <li>Szolgáltatások értékelése és véleményezése: Kliensek számára elérhető értékelési és véleményezési funkciók.</li>
                    <li>Egyéni Téma Kiválasztása: A felhasználók számára elérhető egyéni megjelenési beállítások.</li>
                    <li>Szolgáltatási Statisztikák: Szolgáltatók megtekinthetik a foglalási statisztikákat, beleértve a legnépszerűbb szolgáltatásokat, a legforgalmasabb időszakokat stb.</li>
                    <li>Hűségprogramok: Lehetőség hűségprogramok létrehozására, ahol a visszatérő kliensek kedvezményeket kapnak.</li>
                    <li>Szolgáltatási Csomagok: Kliensek különböző szolgáltatási csomagokat foglalhatnak egyszerre, például bérletek vagy előfizetések.</li>
                    <li>Integráció Közösségi Médiával: Lehetőség a közösségi média fiókok integrálására, hogy könnyen megoszthassák a foglalásaikat és véleményeiket.</li>
                    <li>Szolgáltatások Összehasonlítása: Kliensek összehasonlíthatják a különböző szolgáltatásokat ár, időtartam és értékelés alapján.</li>
                    <li>Csoportos Időpontok Kezelése: Lehetőség csoportos foglalások kezelésére, ahol több kliens foglalhat egy időpontra.</li>
                </ul>

                <hr /> {/* Separator line */}
            </div>
        );
    }
}
