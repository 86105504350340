import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import huLocale from '@fullcalendar/core/locales/hu'; // Import Hungarian locale
import './Booking.css';

const Booking = () => {
    const { userId, serviceId } = useParams(); // Extract userId and serviceId from URL
    const [profileData, setProfileData] = useState(null);
    const [services, setServices] = useState([]);
    const [appointments, setAppointments] = useState([]); // State to store appointments
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentService, setCurrentService] = useState(serviceId || ''); // Track selected service
    const [currentUserId, setCurrentUserId] = useState(null); // Track current user's ID

    useEffect(() => {
        // JWT tokenből kinyert felhasználói ID
        const token = localStorage.getItem('access_token');
        let decodedUserId = null;

        if (token) {
            // Dekódoljuk a JWT token payload részét, hogy megkapjuk a felhasználói azonosítót
            const base64Url = token.split('.')[1]; // A token payload része
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            
            const payload = JSON.parse(jsonPayload);
            decodedUserId = payload.user_id; // A JWT payloadban tárolt user_id
        }

        setCurrentUserId(decodedUserId);
    }, []);

    useEffect(() => {
        if (userId) {
            // Fetch profile data
            fetch(`${process.env.REACT_APP_API}/api/user-profile/${userId}/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('A szerver hibát adott vissza.');
                    }
                    return response.json();
                })
                .then(data => {
                    setProfileData(data); // Set the profile data
                })
                .catch(error => {
                    console.error('Hiba az adatok lekérésekor:', error);
                    setError('Hiba: A szerver hibát adott vissza.');
                })
                .finally(() => setLoading(false));

            // Fetch user services
            fetch(`${process.env.REACT_APP_API}/api/user-services-by-user-id/${userId}/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch services');
                    }
                    return response.json();
                })
                .then(data => {
                    setServices(data.data);
                    if (!serviceId && data.data.length > 0) {
                        setCurrentService(data.data[0].id); // Set first service as default if not provided
                        fetchAppointments(data.data[0].id); // Fetch appointments for the first service by default
                    } else {
                        fetchAppointments(serviceId); // Fetch appointments for the provided serviceId
                    }
                })
                .catch(error => {
                    console.error('Error fetching services:', error);
                });
        }
    }, [userId, serviceId]);

    // Fetch appointments for the selected service
    const fetchAppointments = (serviceId) => {
        if (serviceId) {
            fetch(`${process.env.REACT_APP_API}/api/appointments-by-service-id/${serviceId}/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch appointments');
                    }
                    return response.json();
                })
                .then(data => {
                    // Format appointments for the calendar
                    const formattedAppointments = data.data.map(event => ({
                        id: event.id,
                        title: event.service_name || 'Névtelen szolgáltatás',
                        start: new Date(event.start_time),
                        end: new Date(event.end_time),
                        bookedBy: event.booked_by, // Include booked_by field
                    }));
                    setAppointments(formattedAppointments);
                })
                .catch(error => {
                    console.error('Error fetching appointments:', error);
                });
        }
    };

    // Event click handler to book or unbook an appointment
    const handleEventClick = (clickInfo) => {
        const event = clickInfo.event;
        const appointmentId = event.id;

        if (!event.extendedProps.bookedBy) {
            // If the appointment is not yet booked, we book it
            fetch(`${process.env.REACT_APP_API}/api/appointments/${appointmentId}/book/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    // Refresh appointments list
                    fetchAppointments(currentService);
                } else {
                    alert(data.error || 'Nem sikerült a foglalás.');
                }
            })
            .catch(error => console.error('Hiba a foglalás során:', error));
        } else if (event.extendedProps.bookedBy) { 
            // If the appointment is already booked by the current user, we unbook it
            fetch(`${process.env.REACT_APP_API}/api/appointments/${appointmentId}/unbook/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
                body: JSON.stringify({ booked_by: currentUserId }) // Send current user's ID
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    // Refresh appointments list
                    fetchAppointments(currentService);
                } else {
                    alert(data.error || 'Nem sikerült az időpont törlése.');
                }
            })
            .catch(error => console.error('Hiba az időpont törlése során:', error));
        } else {
            alert('Ez az időpont már foglalt.');
        }
    };

    const handleServiceSelection = (serviceId) => {
        setCurrentService(serviceId);
        fetchAppointments(serviceId); // Fetch appointments for the selected service
    };

    const calendarOptions = {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: 'timeGridWeek',
        headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'timeGridWeek,timeGridDay'
        },
        events: appointments, // Az időpontok átadása a naptárnak
        selectable: false, // Új időpontok kiválasztásának letiltása
        selectMirror: false,
        dayMaxEvents: true,
        slotDuration: '00:10:00', // 10 perces időintervallumok
        slotLabelInterval: '00:10', // 10 percenkénti címkék
        slotMinTime: "08:00:00",
        slotMaxTime: "24:00:00",
        allDaySlot: false,
        locale: huLocale,
        buttonText: {
            today: 'Ma',
            month: 'Hónap',
            week: 'Hét',
            day: 'Nap'
        },
        firstDay: 1, // A hét első napja hétfő
        height: 'auto', // A magasság automatikus beállítása a tartalomhoz igazítva
        eventClick: handleEventClick, // Az esemény kattintásának kezelése

        // Hozzáadott: CSS osztályok az eseményekhez a foglaltság alapján
        eventClassNames: function(arg) {
            if (arg.event.extendedProps.bookedBy) {
                return ['booked-event']; // Csak akkor, ha az időpont foglalt
            }
            return [];
        }
    };

    if (loading) {
        return <div>Adatok betöltése...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="booking-container">
            {profileData && (
                <div className="profile-container">
                    <div className="profile-picture">
                        <img
                            id="profile-picture-img"
                            src={profileData.profile_picture_url ? `${process.env.REACT_APP_API}${profileData.profile_picture_url}` : `${process.env.REACT_APP_API}/media/profile_pictures/default`}
                            alt="Profile"
                        />
                    </div>
                    <div className="profile-details">
                        <p><strong>Keresztnév:</strong> {profileData.user_data.first_name}</p>
                        <p><strong>Vezetéknév:</strong> {profileData.user_data.last_name}</p>
                        <p><strong>Email:</strong> {profileData.user_data.email}</p>
                        <p><strong>Telefonszám:</strong> {profileData.user_data.phone_number}</p>
                    </div>
                </div>
            )}

            <hr /> {/* Separator line */}

            <div className="services-container">
                <h3>Szolgáltatások</h3>
                <table className="services-table">
                    <thead>
                        <tr>
                            <th>Név</th>
                            <th>Leírás</th>
                            <th>Ár</th>
                            <th>Időtartam</th>
                            <th>Kategória</th>
                            <th>Elérhető</th>
                            <th>Akció</th>
                        </tr>
                    </thead>
                    <tbody>
                        {services.map(service => (
                            <tr key={service.id}>
                                <td>{service.name}</td>
                                <td>{service.description}</td>
                                <td>{service.price}</td>
                                <td>{service.duration}</td>
                                <td>{service.category_name}</td>
                                <td>{service.available ? "Igen" : "Nem"}</td>
                                <td>
                                    <button onClick={() => handleServiceSelection(service.id)}>
                                        Időpontok megtekintése
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <hr /> {/* Separator line before calendar */}

            <div className="calendar-container">
                <FullCalendar {...calendarOptions} />
            </div>
        </div>
    );
};

export default Booking;
