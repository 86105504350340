export async function apiRequest(url, options) {
    const accessToken = getAccessToken();
    if (accessToken) {
        options.headers = {
            ...options.headers,
            'Authorization': `Bearer ${accessToken}`,
        };
    }

    let response = await fetch(url, options);

    if (response.status === 401) { // Unauthorized
        const refreshToken = getRefreshToken();
        if (refreshToken) {
            const refreshResponse = await fetch(`${process.env.REACT_APP_API}/api/token/refresh/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ refresh: refreshToken }),
            });

            if (refreshResponse.ok) {
                const refreshData = await refreshResponse.json();
                setTokens(refreshData.access, refreshData.refresh);

                options.headers['Authorization'] = `Bearer ${refreshData.access}`;
                response = await fetch(url, options);
            } else {
                // Ha a frissítési token érvénytelen, akkor kijelentkeztetjük a felhasználót
                alert('A munkamenet lejárt. Kérjük, jelentkezzen be újra.');
                localStorage.clear();
                window.location.href = '/login';
                return;
            }
        }
    }
    
    return response;
}

export function setTokens(access, refresh) {
    localStorage.setItem('access_token', access);
    localStorage.setItem('refresh_token', refresh);
}

export function getAccessToken() {
    return localStorage.getItem('access_token');
}

export function getRefreshToken() {
    return localStorage.getItem('refresh_token');
}
