import React, { useState, useEffect } from 'react';
import './Services.css'; // Import the CSS file
import { apiRequest } from './utils'; // Ensure correct path
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

const Services = () => {
  const [services, setServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('Összes');
  const [expandedServiceId, setExpandedServiceId] = useState(null);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false); // State to manage category dropdown visibility

  const navigate = useNavigate(); // Initialize the navigate function

  useEffect(() => {
    fetchAllServices();
    fetchCategories();
  }, []);

  const fetchAllServices = () => {
    apiRequest(`${process.env.REACT_APP_API}/all-services/`, { // Use the new endpoint
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch services');
        }
        return response.json();
      })
      .then(data => {
        console.log('Services data:', data); // Debug: log data received
        setServices(data.data);
        setFilteredServices(data.data);
      })
      .catch(error => console.error('Error fetching services:', error));
  };

  const fetchCategories = () => {
    apiRequest(`${process.env.REACT_APP_API}/service-categories/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch categories');
        }
        return response.json();
      })
      .then(data => {
        setCategories(data.data);
      })
      .catch(error => console.error('Error fetching categories:', error));
  };

  const toggleCategoryDropdown = () => {
    setIsCategoryOpen(!isCategoryOpen); // Toggle the dropdown visibility
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setIsCategoryOpen(false); // Close dropdown after selection
    if (category === 'Összes') {
      setFilteredServices(services);
    } else {
      setFilteredServices(services.filter(service => service.category_name === category));
    }
  };

  const toggleDetails = (id) => {
    if (expandedServiceId === id) {
      setExpandedServiceId(null); // Collapse if already expanded
    } else {
      setExpandedServiceId(id);
    }
  };

  const redirectToBooking = (userId, serviceId) => {
    if (userId && serviceId) {
      navigate(`/booking/${userId}/${serviceId}`); // Navigate to the booking page with the userId and serviceId
    } else {
      console.error('User ID or Service ID is undefined');
    }
  };

  return (
    <div className="services-container">
      <div className="category-filter">
        <div className="category-dropdown">
          <button className="category-dropdown-button" onClick={toggleCategoryDropdown}>
            {selectedCategory} {/* Always show the selected category */}
          </button>
          {isCategoryOpen && (
            <div className="category-dropdown-menu">
              {['Összes', ...categories.map(category => category.name)].map((category, index) => (
                <div
                  key={index}
                  className={`category-dropdown-item ${category === selectedCategory ? 'selected' : ''}`}
                  onClick={() => handleCategorySelect(category)}
                >
                  {category}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="services-grid">
        {filteredServices.map(service => (
          <div key={service.id} className="service-card">
            <h4>{service.name}</h4>
            <p>{service.description}</p>
            <p>Ár: {service.price} </p>
            <p>Időtartam: {service.duration} óra</p>
            {expandedServiceId === service.id && (
              <div className="service-details">
                <p>Kategória: {service.category_name}</p>
                <p>Elérhető: {service.available ? "Igen" : "Nem"}</p>
                <div className="user-details">
                  <p><strong>Szolgáltató:</strong></p>
                  <p>Név: {service.user_data.first_name || 'N/A'} {service.user_data.last_name || 'N/A'}</p>
                  <p>Email: {service.user_data.email || 'N/A'}</p>
                  <p>Telefonszám: {service.user_data.phone_number || 'N/A'}</p>
                </div>
              </div>
            )}
            <div className="button-container">
              <button onClick={() => toggleDetails(service.id)}>
                {expandedServiceId === service.id ? 'Bezárás' : 'Részletek'}
              </button>
              <button onClick={() => redirectToBooking(service.user_data?.id, service.id)}>Foglalás</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
