import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import ConfirmModal from './ConfirmModal';
import './Appointments.css';
import { apiRequest } from '../../utils'; // apiRequest importálása

const Appointments = () => {
  const [appointments, setAppointments] = useState([]);
  const [services, setServices] = useState([]);
  const [currentService, setCurrentService] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);

  const calendarOptions = {
  plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
  initialView: 'timeGridWeek',
  headerToolbar: {
    left: 'prev,next today',
    center: 'title',
    right: 'timeGridWeek,timeGridDay'
  },
  events: appointments, // Pass the formatted appointments to the calendar
  selectable: true,
  selectMirror: true,
  dayMaxEvents: true,
  slotDuration: '00:10:00',
  slotLabelInterval: '00:10',
  slotMinTime: "08:00:00",
  slotMaxTime: "24:00:00",
  allDaySlot: false,
  locale: 'hu',
  buttonText: {
    today: 'Ma',
    month: 'Hónap',
    week: 'Hét',
    day: 'Nap'
  },
  firstDay: 1,
  height: 'auto',
  select: handleDateSelect,
  eventClick: handleEventClick,
  eventColor: 'lightgrey', // Default szín
  eventContent: (eventInfo) => ({
    html: `<b>${eventInfo.event.title}</b>` // Esemény címének megjelenítése
  }),
};

  useEffect(() => {
    fetchAppointments();
    fetchUserServices();
  }, []);

  const fetchAppointments = () => {
    apiRequest(`${process.env.REACT_APP_API}/appointments/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Hiba az időpontok lekérdezése során');
        }
        return response.json();
      })
      .then(data => {
        // Format appointments for the calendar
        const formattedAppointments = data.map(event => ({
          id: event.id,
          title: `${event.service_name || 'Névtelen szolgáltatás'} - ${event.booked_by_data ? event.booked_by_data.first_name + ' ' + event.booked_by_data.last_name : 'Nincs foglalva'}`,
          start: new Date(event.start_time),
          end: new Date(event.end_time),
          bookedBy: event.booked_by, // Include booked_by field
          color: event.booked_by ? 'red' : '', // Set color to red if booked
        }));
        setAppointments(formattedAppointments);
      })
      .catch(error => console.error('Hiba az időpontok lekérdezésekor:', error));
  };

  const fetchUserServices = () => {
    apiRequest(`${process.env.REACT_APP_API}/user-services/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Hiba a szolgáltatások lekérdezése során');
        }
        return response.json();
      })
      .then(data => {
        if (Array.isArray(data.data)) {
          setServices(data.data);
          if (data.data.length > 0) {
            setCurrentService(data.data[0].id.toString()); // Az első szolgáltatás beállítása alapértelmezettként
          }
        } else {
          console.error('Vártunk egy tömböt, de kaptunk:', data.data);
          setServices([]);
        }
      })
      .catch(error => console.error('Hiba a szolgáltatások lekérdezésekor:', error));
  };

  function handleDateSelect(selectInfo) {
    if (!currentService) {
      alert('Kérjük, válasszon egy szolgáltatást a lista alján!');
      return;
    }
  
    // JWT tokenből kinyert felhasználói ID
    const token = localStorage.getItem('access_token');
    let userId = null;
    
    if (token) {
      // Dekódoljuk a JWT token payload részét, hogy megkapjuk a felhasználói azonosítót
      const base64Url = token.split('.')[1]; // A token payload része
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      
      const payload = JSON.parse(jsonPayload);
      userId = payload.user_id; // A JWT payloadban tárolt user_id
    }
  
    if (!userId) {
      alert('Bejelentkezési probléma, próbálkozz újra!');
      return;
    }
  
    // Kiválasztott szolgáltatás ID
    const selectedServiceId = parseInt(currentService);
  
    // Adatok a naptárból
    const eventData = {
      start_time: selectInfo.startStr, // Kiválasztott kezdési időpont
      end_time: selectInfo.endStr, // Kiválasztott befejezési időpont
      service: selectedServiceId, // Kiválasztott szolgáltatás ID
      user: userId // Dinamikus felhasználói azonosító
    };
  
    // Posztolás a backendre
    apiRequest(`${process.env.REACT_APP_API}/appointments/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(eventData),
    })
    .then(response => response.json())
    .then(data => {
      // Az esemény hozzáadása a naptár állapotához
      setAppointments(prevAppointments => [...prevAppointments, {
        ...data,
        start: new Date(data.start_time),
        end: new Date(data.end_time),
        title: services.find(service => service.id === selectedServiceId)?.name || 'Névtelen szolgáltatás',
        color: data.booked_by ? 'red' : '', // Set color to red if booked
      }]);
      console.log('Sikeres foglalás:', data);
    })
    .catch(error => console.error('Hiba az időpont mentésekor:', error));
  } 

  function handleEventClick(clickInfo) {
    setCurrentEvent(clickInfo.event);
    setModalOpen(true);
  }

  function confirmDelete() {
    // DELETE kérés az API-hoz
    apiRequest(`${process.env.REACT_APP_API}/appointments/${currentEvent.id}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Hiba az időpont törlése során');
        }
        // Újból lekérjük az időpontokat a frissített állapothoz
        fetchAppointments();
        setModalOpen(false);
      })
      .catch(error => console.error('Hiba az időpont törlésekor:', error));
  }

  function closeConfirmModal() {
    setModalOpen(false);
  }

  return (
    <div>
      <div className="service-select">
        <label>Szolgáltatás kiválasztása:</label>
        <select
          onChange={e => setCurrentService(e.target.value)}
          value={currentService}
        >
          {services.length === 0 ? (
            <option value="">Válasszon szolgáltatást</option>
          ) : (
            services.map(service => (
              <option key={service.id} value={service.id}>
                {service.name}
              </option>
            ))
          )}
        </select>
      </div>
      <FullCalendar {...calendarOptions} />
      <ConfirmModal
        isOpen={modalOpen}
        onClose={closeConfirmModal}
        onConfirm={confirmDelete}
        message={`Biztosan törölni szeretné az eseményt: '${currentEvent?.title}'?`}
      />
    </div>
  );
};

export default Appointments;
