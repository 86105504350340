import React, { useState, useEffect } from 'react';
import './Services.css'; // Importáljuk a CSS fájlt
import { apiRequest } from '../../utils'; // Importálás

const Services = () => {
  const [services, setServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [formData, setFormData] = useState({
    id: null,
    name: '',
    description: '',
    price: '',
    duration: '',
    available: true,
    category: ''
  });

  useEffect(() => {
    fetchUserServices();
    fetchCategories();
  }, []);

  const fetchUserServices = () => {
    apiRequest(`${process.env.REACT_APP_API}/user-services/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch services');
        }
        return response.json();
      })
      .then(data => {
        setServices(data.data);
      })
      .catch(error => console.error('Error fetching services:', error));
  };

  const fetchCategories = () => {
    apiRequest(`${process.env.REACT_APP_API}/service-categories/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch categories');
        }
        return response.json();
      })
      .then(data => {
        setCategories(data.data);
      })
      .catch(error => console.error('Error fetching categories:', error));
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const method = formData.id ? 'PUT' : 'POST';
    const url = `${process.env.REACT_APP_API}/user-services/${formData.id ? formData.id + '/' : ''}`;

    apiRequest(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: JSON.stringify({
        name: formData.name,
        description: formData.description,
        price: parseFloat(formData.price), // Ensure price is a number
        duration: parseFloat(formData.duration), // Ensure duration is a number
        available: formData.available,
        category: formData.category
        // Ne adj hozzá user ID-t itt!
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          throw new Error(data.error);
        }
        alert(formData.id ? 'Service updated successfully' : 'Service created successfully');
        fetchUserServices();
        resetForm();
      })
      .catch(error => {
        console.error('Error:', error);
        alert('Hiba történt: ' + error.message);
      });
  };

  const handleEdit = (service) => {
    setFormData(service);
  };

  const handleDelete = (id) => {
    if (window.confirm('Biztosan törölni szeretné ezt a szolgáltatást?')) {
      apiRequest(`${process.env.REACT_APP_API}/user-services/${id}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to delete service');
          }
          alert('Service deleted successfully');
          fetchUserServices();
        })
        .catch(error => {
          console.error('Error deleting service:', error);
          alert('Hiba történt a törlés során: ' + error.message);
        });
    }
  };

  const resetForm = () => {
    setFormData({
      id: null,
      name: '',
      description: '',
      price: '',
      duration: '',
      available: true,
      category: ''
    });
  };

  return (
    <div className="services-container">
      <div className="services-content">
        <form className="services-form" onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label>Szolgáltatás Neve:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Leírás:</label>
            <input
              type="text"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Ár:</label>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Időtartam (óra):</label>
            <input
              type="number"
              name="duration"
              value={formData.duration}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Kategória:</label>
            <select
              name="category"
              value={formData.category}
              onChange={handleInputChange}
              required
            >
              <option value="">Válasszon kategóriát</option>
              {categories.map(category => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group checkbox-group">
            <label>
              Elérhető:
              <input
                type="checkbox"
                name="available"
                checked={formData.available}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <button type="submit" className="submit-btn">
            {formData.id ? 'Szolgáltatás Frissítése' : 'Új Szolgáltatás Létrehozása'}
          </button>
        </form>

        <div className="services-table-container">
          <h3>Meglévő Szolgáltatások</h3>
          <table className="services-table">
            <thead>
              <tr>
                <th>Név</th>
                <th>Leírás</th>
                <th>Ár</th>
                <th>Időtartam</th>
                <th>Kategória</th>
                <th>Elérhető</th>
                <th>Műveletek</th>
              </tr>
            </thead>
            <tbody>
              {services.map(service => (
                <tr key={service.id}>
                  <td>{service.name}</td>
                  <td>{service.description}</td>
                  <td>{service.price}</td>
                  <td>{service.duration}</td>
                  <td>{service.category_name}</td>
                  <td>{service.available ? "Igen" : "Nem"}</td>
                  <td>
                    <button className="edit-btn" onClick={() => handleEdit(service)}>Szerkesztés</button>
                    <button className="delete-btn" onClick={() => handleDelete(service.id)}>Törlés</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Services;
