import React, { useState, useEffect } from 'react';
import './ProfileData.css'; // Styling

const Profile = () => {
    const [profileData, setProfileData] = useState({
        profile_picture_url: null,
        user_data: {
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            username: ''
        }
    });

    // Fetch user profile data on component mount
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API}/api/user-profile/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setProfileData(prevState => ({
                ...prevState,
                profile_picture_url: data.profile_picture_url || '/media/profile_pictures/default.jpg_MynwFn5.jpg', // Ensure default.jpg is used
                user_data: data.user_data
            }));
        })
        .catch(error => console.error('There was a problem with the fetch operation:', error));
    }, []);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('profile_picture', file);

            fetch(`${process.env.REACT_APP_API}/api/upload-profile-picture/`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (data.success) {
                    setProfileData(prevState => ({
                        ...prevState,
                        profile_picture_url: `/media/${data.file_name}`
                    }));
                    alert('Profilkép sikeresen feltöltve');
                } else {
                    alert('Hiba történt a profilkép feltöltésekor');
                }
            })
            .catch(error => console.error('There was a problem with the fetch operation:', error));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileData(prevState => ({
            ...prevState,
            user_data: {
                ...prevState.user_data,
                [name]: value
            }
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch(`${process.env.REACT_APP_API}/api/user-profile/update/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            },
            body: JSON.stringify(profileData.user_data),
        })
        .then(response => response.json())
        .then(data => {
            if (data.error) {
                alert('Hiba történt az adatok frissítésekor');
            } else {
                alert('Adatok sikeresen frissítve');
            }
        })
        .catch(error => console.error('Error updating profile:', error));
    };

    return (
        <div className="profile-container">
            <div className="profile-picture">
                <img 
                    id="profile-picture-img"
                    src={`${process.env.REACT_APP_API}${profileData.profile_picture_url || '/media/profile_pictures/default.jpg'}`} 
                    alt="Profile" 
                />
                <label className="custom-file-upload">
                    Fájl kiválasztása
                    <input type="file" accept="image/*" onChange={handleImageUpload} />
                </label>
            </div>
            <div className="profile-details">
                <form onSubmit={handleSubmit}>
                    <h3>{profileData.user_data.first_name} {profileData.user_data.last_name}</h3>
                    <p><strong>Felhasználónév:</strong> {profileData.user_data.username}</p>
                    <div>
                        <label>Keresztnév:</label>
                        <input
                            type="text"
                            name="first_name"
                            value={profileData.user_data.first_name}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label>Vezetéknév:</label>
                        <input
                            type="text"
                            name="last_name"
                            value={profileData.user_data.last_name}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label>Email:</label>
                        <input
                            type="email"
                            name="email"
                            value={profileData.user_data.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label>Telefonszám:</label>
                        <input
                            type="text"
                            name="phone_number"
                            value={profileData.user_data.phone_number}
                            onChange={handleChange}
                        />
                    </div>
                    <button type="submit">Adatok Frissítése</button>
                </form>
            </div>
        </div>
    );
};

export default Profile;
