// src/components/ServiceProfile/ServiceProfile.js
import React from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';
import ProfileData from './ProfileData';
import Services from './Services';
import Appointments from './Appointments';
import './ServiceProfile.css';

const ServiceProfile = () => {
    return (
        <div className="service-profile">
            <nav className="profile-nav">
                <NavLink 
                    to="profiledata" 
                    className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}
                >
                    Profil Adatok
                </NavLink>
                <NavLink 
                    to="services" 
                    className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}
                >
                    Szolgáltatások
                </NavLink>
                <NavLink 
                    to="appointments" 
                    className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}
                >
                    Időpontok
                </NavLink>
            </nav>
            <div className="profile-content">
                <Routes>
                    <Route path="/" element={<ProfileData />} />
                    <Route path="profiledata" element={<ProfileData />} />
                    <Route path="services" element={<Services />} />
                    <Route path="appointments" element={<Appointments />} />
                </Routes>
            </div>
        </div>
    );
};

export default ServiceProfile;
