import React, { useState } from 'react';
import './register.css';
import { useNavigate } from 'react-router-dom';

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

function Register() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const csrftoken = getCookie('csrftoken');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const response = await fetch(`${process.env.REACT_APP_API}/api/register/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify({
                username,
                password,
                first_name: firstName,
                last_name: lastName,
                email,
                phone_number: phoneNumber
            })
        });
        const data = await response.json();
        console.log('Response:', response);
        console.log('Data:', data);

        if (response.status === 201) {
            alert('Sikeres regisztráció');
            navigate('/login'); // Átirányítás a bejelentkezésre
        } else {
            alert(data.error);
        }
    };

    return (
        <div className="containerLogin">
            <h2>Regisztráció</h2>
            <form onSubmit={handleSubmit}>
                <label>
                    Felhasználónév:
                    <input type="text" value={username} onChange={e => setUsername(e.target.value)} required />
                </label>
                <label>
                    Jelszó:
                    <input type="password" value={password} onChange={e => setPassword(e.target.value)} required />
                </label>
                <label>
                    Keresztnév:
                    <input type="text" value={firstName} onChange={e => setFirstName(e.target.value)} required />
                </label>
                <label>
                    Vezetéknév:
                    <input type="text" value={lastName} onChange={e => setLastName(e.target.value)} required />
                </label>
                <label>
                    Email:
                    <input type="email" value={email} onChange={e => setEmail(e.target.value)} required />
                </label>
                <label>
                    Telefonszám:
                    <input type="text" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} required />
                </label>
                <button type="submit">Regisztráció</button>
            </form>
        </div>
    );
}

export default Register;
